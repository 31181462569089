import React from 'react';
import { StyledDiv, StyledWrapper } from './Styled';
import CreateMeetingForm from '../CreateMeetingForm';

const CreateMeetingFormSelector: React.FC = () => (
    <StyledWrapper>
        <StyledDiv><CreateMeetingForm /></StyledDiv>
    </StyledWrapper>
);

export default CreateMeetingFormSelector;
