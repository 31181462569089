import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Flex,
    Heading,
    useMeetingManager,
    Modal,
    ModalBody,
    ModalHeader,
    DeviceLabels,
} from 'amazon-chime-sdk-component-library-react';
import { getErrorContext } from '../../providers/ErrorProvider';
import routes from '../../constants/routes';
import Card from '../../components/Card';
import Spinner from '../../components/Spinner';
import DevicePermissionPrompt from '../DevicePermissionPrompt';
import { createGetAttendeeCallback, createMeeting } from '../../utils/api';
import { useAppState } from '../../providers/AppStateProvider';
import { MeetingMode } from '../../types';

// eslint-disable-next-line no-restricted-globals
const query = new URLSearchParams(location.search);

const CreateMeetingForm: React.FC = () => {
    const meetingManager = useMeetingManager();
    const {
        setAppMeetingInfo,
        region: appRegion,
        setMeetingMode
    } = useAppState();

    const [meetingName, setMeetingName] = useState(query.get("title"));
    const [meetingId, setMeetingId] = useState(query.get("id"));
    const [email, setEmail] = useState(query.get("email"))

    const [region] = useState(appRegion);
    const [isLoading, setIsLoading] = useState(false);

    const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
    const history = useHistory();

    useEffect(() => {
        const handleCreateMeeting = async (): Promise<void> => {
            if (!meetingName || !email || !meetingId) {
                if (!email) {
                    updateErrorMessage('No email was provided');
                }

                if (!meetingName) {
                    updateErrorMessage('Invalid meeting name');
                }

                if (!meetingId) {
                    updateErrorMessage('Invalid meeting id');
                }

                return;
            }

            const mUUID = meetingId.trim().toLocaleLowerCase();
            const mName = meetingName.trim().toLocaleLowerCase();
            const aEmail = email.trim().toLocaleLowerCase();

            setIsLoading(true);
            meetingManager.getAttendee = createGetAttendeeCallback(mUUID);

            try {
                const { JoinInfo, host } = await createMeeting(mUUID, mName, aEmail);

                await meetingManager.join({
                    meetingInfo: JoinInfo.Meeting,
                    attendeeInfo: JoinInfo.Attendee,
                    deviceLabels: DeviceLabels.AudioAndVideo,
                });

                setAppMeetingInfo(mUUID, mName, host.name, region);
                setMeetingMode(MeetingMode.TEACHER);

                await meetingManager.start();
                history.push(`${routes.MEETING}/${meetingName}`);

            } catch (error: any) {
                updateErrorMessage(error.message);
            }
        };
        handleCreateMeeting()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const closeError = (): void => {
        updateErrorMessage('');
        setMeetingName('');
        setMeetingId("")
        setEmail("")
        setIsLoading(false);
    };

    return (
        <form>
            <Heading tag="h1" level={4} css="margin-bottom: 1rem">
                Join a meeting
            </Heading>
            {/* @ts-expect-error */}
            <Flex
                container
                layout="fill-space-centered"
                style={{ marginTop: '2.5rem' }}
            >
                {isLoading ? (
                    <Spinner />
                ) : null}
            </Flex>
            {errorMessage && (
                <Modal size="md" onClose={closeError}>
                    <ModalHeader title={`Meeting ID: ${meetingName}`} />
                    <ModalBody>
                        <Card
                            title="Unable to join meeting"
                            description="There was an issue finding that meeting. The meeting may have already ended, or your authorization may have expired."
                            smallText={errorMessage}
                        />
                    </ModalBody>
                </Modal>
            )}
            <DevicePermissionPrompt />
        </form>
    );
};

export default CreateMeetingForm;
