import React from 'react';
import { StyledLayout } from './Styled';
import { VersionLabel } from '../../utils/VersionLabel';
import CreateMeetingFormSelector from '../../containers/CreateMeetingFormSelector';

const Create = () => (
  <StyledLayout>
    <CreateMeetingFormSelector />
    <VersionLabel />
  </StyledLayout>
);

export default Create;
