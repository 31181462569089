



export type FormattedDeviceType = {
    deviceId: string;
    label: string;
};

export type DeviceType = MediaDeviceInfo | FormattedDeviceType;

export type SelectedDeviceType = string | null;

export type DeviceTypeContext = {
    devices: DeviceType[];
    selectedDevice: SelectedDeviceType;
};

export type LocalVideoContextType = {
    isVideoEnabled: boolean;
    toggleVideo: () => Promise<void>;
};

export type DeviceConfig = {
    additionalDevices?: boolean;
};

export type LocalAudioOutputContextType = {
    isAudioOn: boolean;
    toggleAudio: () => void;
};

export type ContentShareControlContextType = {
    isContentSharePaused: boolean;
    toggleContentShare: () => Promise<void>;
    togglePauseContentShare: () => void;
};

export enum MeetingMode {
    Spectator,
    ATTENDEE,
    TEACHER,
}
