import React, { useContext, useState, ReactNode } from 'react';
import { MeetingMode } from '../types';

type Props = {
    children: ReactNode;
};

interface AppStateValue {
    meetingId: string;
    meetingName: string;
    userName: string;
    theme: string;
    region: string;
    meetingMode: MeetingMode;
    toggleTheme: () => void;
    setAppMeetingInfo: (meetingId: string, meetingName: string, name: string, region: string) => void;
    setMeetingMode: (meetingMode: MeetingMode) => void;
}

const AppStateContext = React.createContext<AppStateValue | null>(null);

export function useAppState(): AppStateValue {
    const state = useContext(AppStateContext);

    if (!state) {
        throw new Error('useAppState must be used within AppStateProvider');
    }

    return state;
}

export function AppStateProvider({ children }: Props) {
    const [region, setRegion] = useState('us-east-1');
    const [meetingId, setMeetingId] = useState('');
    const [meetingName, setMeetingName] = useState("")
    const [meetingMode, setMeetingMode] = useState(MeetingMode.ATTENDEE);
    const [userName, setUserName] = useState('');
    const [theme, setTheme] = useState(() => {
        const storedTheme = localStorage.getItem('theme');
        return storedTheme || 'light';
    });

    const toggleTheme = (): void => {
        if (theme === 'light') {
            setTheme('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            setTheme('light');
            localStorage.setItem('theme', 'light');
        }
    };

    const setAppMeetingInfo = (
        meetingId: string,
        meetingName: string,
        name: string,
        region: string
    ) => {
        setRegion(region);
        setMeetingId(meetingId);
        setMeetingName(meetingName)
        setUserName(name);
    };

    const providerValue = {
        meetingId,
        meetingName,
        userName,
        theme,
        region,
        meetingMode,
        toggleTheme,
        setAppMeetingInfo,
        setMeetingMode,
    };

    return (
        <AppStateContext.Provider value={providerValue}>
            {children}
        </AppStateContext.Provider>
    );
}
