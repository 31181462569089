import React from 'react';
import MeetingForm from '../MeetingForm';
import { StyledDiv, StyledWrapper } from './Styled';

const MeetingFormSelector: React.FC = () => (
    <StyledWrapper>
        <StyledDiv><MeetingForm /></StyledDiv>
    </StyledWrapper>
);

export default MeetingFormSelector;
