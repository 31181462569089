import React from 'react';
import {
    VideoTileGrid,
    UserActivityProvider
} from 'amazon-chime-sdk-component-library-react';
import { StyledLayout, StyledContent } from './Styled';
import NavigationControl from '../../containers/Navigation/NavigationControl';
import { useNavigation } from '../../providers/NavigationProvider';
import MeetingDetails from '../../containers/MeetingDetails';
import MeetingControls from '../../containers/MeetingControls';
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect';
import DynamicMeetingControls from '../../containers/DynamicMeetingControls';
import { MeetingMode } from '../../types';

const MeetingView = (props: { mode: MeetingMode }) => {
    useMeetingEndRedirect();
    const { showNavbar, showRoster } = useNavigation();

    return (
        // @ts-expect-error 
        <UserActivityProvider>
            <StyledLayout showNav={showNavbar} showRoster={showRoster}>
                <StyledContent>
                    <VideoTileGrid
                        className="videos"
                        noRemoteVideoView={<MeetingDetails />}
                    />
                    {props.mode === MeetingMode.Spectator ?
                        <DynamicMeetingControls /> :
                        <MeetingControls />
                    }
                </StyledContent>
                <NavigationControl />
            </StyledLayout>
        </UserActivityProvider>
    );
};

export default MeetingView;
