const BASE_URL = "https://meetings-api.classcube.online/api/v1";

interface CreateMeetingResponse {
    JoinInfo: {
        Attendee: any;
        Meeting: any;
    };
    host: {
        name: string
    }
}

interface JoinMeetingResponse {
    JoinInfo: {
        Attendee: any;
        Meeting: any;
    };
    info: {
        mode: "TEACHER" | "ATTENDEE",
        meetingName: string
    }
}

export async function createMeeting(
    uuid: string,
    title: string,
    email: string,
): Promise<CreateMeetingResponse> {
    const response = await fetch(
        `${BASE_URL}/meetings/`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, title, uuid })
        }
    );

    const data = await response.json();

    if (data.error) {
        throw new Error(`Server error: ${data.error}`);
    }

    return data.data;
}

export async function joinMeeting(
    meetingId: string,
    name: string,
    email: string,
): Promise<JoinMeetingResponse> {
    const response = await fetch(
        `${BASE_URL}/meetings/${encodeURIComponent(meetingId)}`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, email })
        }
    );
    const data = await response.json();

    if (data.error) {
        throw new Error(`Server error: ${data.error}`);
    }

    return data.data;
}

export function createGetAttendeeCallback(meetingId: string) {
    return async (chimeAttendeeId: string, externalUserId?: string) => {
        const res = await fetch(`${BASE_URL}/meetings/${encodeURIComponent(meetingId)}/${encodeURIComponent(chimeAttendeeId)}`, {
            method: 'GET'
        });

        if (!res.ok) {
            throw new Error('Invalid server response');
        }

        const data = await res.json();

        return {
            name: data.data.AttendeeInfo.Name
        };
    };
}

export async function endMeeting(meetingId: string) {
    const res = await fetch(
        `${BASE_URL}/meetings/${encodeURIComponent(meetingId)}`,
        {
            method: 'DELETE'
        }
    );

    if (!res.ok) {
        throw new Error('Server error ending meeting');
    }
}
