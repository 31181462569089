


import React from 'react';
import {
    Navbar,
    NavbarHeader,
    NavbarItem,
    Attendees,
    Eye,
    SignalStrength
} from 'amazon-chime-sdk-component-library-react';
import { useNavigation } from '../../providers/NavigationProvider';
import { useAppState } from '../../providers/AppStateProvider';
import LocalMediaStreamMetrics from '../LocalMediaStreamMetrics';

const Navigation = () => {
    const { toggleRoster, closeNavbar } = useNavigation();
    const { theme, toggleTheme } = useAppState();

    return (
        <Navbar className="nav" flexDirection="column" container>
            <NavbarHeader title="Navigation" onClose={closeNavbar} />
            <NavbarItem
                icon={<Attendees />}
                onClick={toggleRoster}
                label="Attendees"
            />
            <NavbarItem
                icon={<Eye />}
                onClick={toggleTheme}
                label={theme === 'light' ? 'Dark mode' : 'Light mode'}
            />
            <NavbarItem
                icon={<SignalStrength />}
                onClick={() => { }}
                label="Media metrics"
            >
                <LocalMediaStreamMetrics />
            </NavbarItem>
        </Navbar>
    );
};

export default Navigation;
